<script>
import Layout from "../../layouts/main.vue";

import "prismjs";
import "prismjs/themes/prism.css";
import { mapActions, mapGetters } from "vuex";
import StarRating from 'vue-star-rating'
import Swal from "sweetalert2";

import {
  required, helpers
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'


export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      title: "Services",
      services: [],
      assetUrl: process.env.VUE_APP_ENVIRONMENT == 'local' ? process.env.VUE_APP_LOCAL_URL : 'https://e-triq.com/api/triq-backend-laravel/storage/app/public/',
      baseUrl: process.env.VUE_APP_API_URL,
      serviceOrder: {
        user_id: null,
        service_category_id: null,
        service_id: null,
        vendor_id: null,
        price: 1,
        tenure: 'onetime'
      },
      step: 1,
      users: [],
      selectedService: null,
      rating: 5,
      selectVanderId: null,

      orderPeriodicity: 'onetime',
      selectedUser: null,

      availability: null,
      isShowModal: false,
      isShowSelectModal: false,
      showAvailiblityIndex: 0,

      eventName: null,
      selectSlot: null,
      selectDuarton: 15,
      selectzone: null,
      selectDate: null,
      isSubmited: false,

      todayDate: null,
      filterEventPerdate: [],

      selectVanderUser: null,
      eventPayload: {},

      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        dateClick: this.handleDateClick,
        // events: [
        //   { title: 'event 1', start: '2023-11-10T13:06:53.422Z', end: '2023-11-10T14:06:53.422Z' },
        //   { title: 'event 2', date: '2023-11-11T15:30:00' }
        // ],
        events: [],
        headerToolbar: {
          left: 'title',
          right: 'prev,next',
        },
      }
    };
  },
  validations() {
    return {
      eventName: {
        required: helpers.withMessage("Name is required", required),
      },
      selectSlot: {
        required: helpers.withMessage("Slot is required", required),
      },
      selectDuarton: {
        required: helpers.withMessage("Duration is required", required),
      },
      selectzone: {
        required: helpers.withMessage("Timezone is required", required),
      },
    }
  },
  components: {
    Layout,
    StarRating,
    FullCalendar
  },
  computed: {
    ...mapGetters({
      userData: "auth/user",
      // getServices: "service/getServices",
      getScategory: "scategory/setScategory",
    }),
    titles() {
      if (this.step == 1) {
        return 'Choose service category'
      } else if (this.step == 2) {
        return 'Select Service'
      } else if (this.step == 3) {
        return 'Select Vendor'
      }
      return null
    },
    orderAmount() {
      if (this.selectedUser && this.selectedUser.user_details && this.selectedUser.user_details.tier) {
        var tier = this.selectedUser.user_details.tier
        var servicePrice = JSON.parse(this.selectedService['tier' + tier])
        if (this.orderPeriodicity == 'onetime') {
          return servicePrice.onetime
        } else if (this.orderPeriodicity == 'monthly') {
          return servicePrice.monthly
        } else if (this.orderPeriodicity == 'quaterly') {
          return servicePrice.quaterly
        } else {
          return servicePrice.yearly
        }
      }

      if (this.selectedUser && this.selectedUser.role_id == 4) {
        if (this.selectDuarton == 45) {
          return this.selectedUser.user_details.consultancy_charge_forty
        } else if (this.selectDuarton == 30) {
          return this.selectedUser.user_details.consultancy_charge_thirty
        } else {
          return this.selectedUser.user_details.consultancy_charge_fifthteen
        }
      }
      return 0
    },
    stayEventZone() {
      if (this.filterEventPerdate && this.filterEventPerdate.length > 0) {
        return this.filterEventPerdate.map(
          (curr) => {
            let Startime = new Date(Date.parse(curr.start))
            let Endtime = new Date(Date.parse(curr.end))
            return `${String(Startime.getHours()).padStart(2, '0')}:${String(Startime.getMinutes()).padStart(2, '0')}:${String(Startime.getSeconds()).padStart(2, '0')}-${String(Endtime.getHours()).padStart(2, '0')}:${String(Endtime.getMinutes()).padStart(2, '0')}:${String(Endtime.getSeconds()).padStart(2, '0')}`
          }
        )
      }
      return []
    }
  },
  async mounted() {
    await this.setScategory();
  },
  methods: {
    ...mapActions({
      getServicesByUser: "service/getServicesByUser",
      setScategory: "scategory/setScategory",
      getServicesByCategory: "service/getServicesByCategory",
      getUsersByService: "users/getUsersByService",
      getUserAvailability: "availability/getUserAvailability",
      addOrderEvent: "order/addOrderEvent",
      getOrderEvent: "order/getOrderEvent",
    }),
    getServicesByCategoryData() {
      this.getServicesByCategory(this.serviceOrder.service_category_id)
        .then((res) => {
          if (res.data.status) {
            this.services = res.data.data.services
            this.step++
          } else {
            this.services = [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getPrice(prices) {
      var priceData = JSON.parse(prices)
      return priceData
      // return `One Time: ${priceData.onetime}, Monthly: ${priceData.onetime}, Quaterly: ${priceData.quaterly}, Yearly: ${priceData.yearly}`;
    },
    nameInitials(name) {
      if (name) {
        var nameArray = name.split(" ");
        var initials = '';
        if (nameArray.length === 1) {
          return nameArray[0].charAt(0) + "" + nameArray[0].charAt(1);
        } else {
          initials = nameArray[0].charAt(0);
        }
        for (var i = (nameArray.length - 1); i < nameArray.length; i++) {
          initials += nameArray[i].charAt(0);
        }
        return initials.toUpperCase();
      }
      return null
    },
    getImage(image) {
      if (image) {
        return this.assetUrl + image
      }
      return null
    },
    setCategory(categoryId) {
      this.serviceOrder.service_category_id = categoryId
      this.getServicesByCategoryData()
    },
    setService(service) {
      this.serviceOrder.service_id = service.id
      this.selectedService = service
      this.getUsersByServiceData()
    },
    getUsersByServiceData() {
      this.getUsersByService(this.serviceOrder.service_id)
        .then((res) => {
          if (res.data.status) {
            var users = []
            res.data.data.users.map(u => {
              if (u.user)
                users.push(u)
            })
            this.users = users
            this.step++
          } else {
            this.users = [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getTierPrice(tier) {
      if (tier) {
        if (this.selectedService && this.selectedService['tier' + tier]) {
          var priceData = JSON.parse(this.selectedService['tier' + tier])
          if (priceData) {
            return priceData.onetime
          }
        }
      }
      return null
    },
    setServiceUser(user) {
      var price = 0
      if (user && user.user_details && user.user_details.tier) {
        price = this.getTierPrice(user.user_details.tier)
      }
      this.step++;
      this.selectedUser = user
      this.serviceOrder.user_id = this.userData.id
      this.serviceOrder.vendor_id = user.id
      this.serviceOrder.price = price
      // if(confirm('Are you sure want to place order')){
      //   var redirect_url = 'https://e-triq.com/api/triq-backend-laravel/public/ccavenue/payment?user=' +  this.serviceOrder.user_id +  '&amount=' + this.serviceOrder.price + '&language=EN&service_id=' + this.serviceOrder.service_id +'&vendor_id=' + this.serviceOrder.vendor_id +'&tenure=' + this.serviceOrder.tenure;
      //   window.location.href = redirect_url;
      // }
    },
    placeOrder() {
      var self = this
      Swal.fire({
        title: "Are you sure want to place order?",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Yes Order!",
        confirmButtonClass: "btn btn-success w-xs me-2",
        cancelButtonClass: "btn btn-danger w-xs",
        denyButtonClass: "btn btn-info w-xs me-2",
        buttonsStyling: false,
        denyButtonText: "Don't order",
        showCloseButton: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (this.selectedUser && this.selectedUser.role_id && this.selectedUser.role_id == 4) {
            this.eventPayload.order_id = null
            this.addOrderEvent(this.eventPayload)
              .then(
                ({ data }) => {
                  if (data.status) {
                    this.calendarOptions.events.push({ title: data.data.event.title, start: data.data.event.start, end: data.data.event.end })
                    var redirect_url = 'https://e-triq.com/api/triq-backend-laravel/public/ccavenue/payment?user=' + self.serviceOrder.user_id + '&amount=' + self.orderAmount + '&language=EN&service_id=' + self.serviceOrder.service_id + '&vendor_id=' + self.serviceOrder.vendor_id + '&tenure=' + self.serviceOrder.tenure + '&event_id=' + data.data.event.id;
                    window.location.href = redirect_url;
                  }
                }
              )
          } else {
            var redirect_url = 'https://e-triq.com/api/triq-backend-laravel/public/ccavenue/payment?user=' + self.serviceOrder.user_id + '&amount=' + self.orderAmount + '&language=EN&service_id=' + self.serviceOrder.service_id + '&vendor_id=' + self.serviceOrder.vendor_id + '&tenure=' + self.serviceOrder.tenure;
            window.location.href = redirect_url;
          }
        }
      });
    },
    getAvailability(user_id, user) {
      this.selectVanderId = user_id,
        this.getUserAvailability(user_id)
          .then(res => this.availability = res.data.data.availabilities)

      this.getOrderEvent(user_id)
        .then(({ data }) => { console.log(data.data.events); this.calendarOptions.events = [...data.data.events] })


      this.selectVanderUser = user;
      this.eventName = `Consultancy with ${user.name}`
    },
    handleDateClick({ date }) {
      this.filterEventPerdate = []
      this.calendarOptions.events.map(
        (curr) => {
          let currDate = new Date(Date.parse(curr.start))
          if (currDate.toLocaleDateString() == date.toLocaleDateString()) {
            if (this.filterEventPerdate.length > 0) {
              this.filterEventPerdate.map(
                (currFil) => {
                  if (currFil.id != curr.id) {
                    this.filterEventPerdate.push(curr)
                  }
                }
              )
            } else {
              this.filterEventPerdate.push(curr)
            }
          }
        }
      )

      if ((new Date() - date) < 0 || date.toLocaleDateString() == new Date().toLocaleDateString()) {
        this.selectDate = date
        this.isShowSelectModal = true
        this.showAvailiblityIndex = date.getDay();
      }
    },
    availabilityFilter(showAvailiblityIndex) {
      return this.availability?.filter((curr, index) => {
        if (showAvailiblityIndex == 0) {
          return index == 6
        }
        return index + 1 == showAvailiblityIndex
      })[0]
    },
    availableTextShow(obj) {
      if (obj) {
        if (obj.slot1_start_time == obj.slot1_end_time && obj.slot2_start_time == obj.slot2_end_time && obj.slot3_start_time == obj.slot3_end_time) {
          return false
        }
        return true
      }
      return false
    },
    getTimeDiffrent(time) {
      if (time) {
        let startTime = Date.parse(`04 Dec 1995 ${time.split('-')[0]} GMT`)
        let endTime = Date.parse(`04 Dec 1995 ${time.split('-')[1]} GMT`)

        return Math.floor((endTime - startTime) / 1000 / 60)
      }
      return time
    },
    getTimeZone(prevDuration, nextDuration, stratTime) {
      let date = Date.parse(`04 Dec 1995 ${stratTime} GMT`)
      return `${new Date(date + (prevDuration * 60000)).toGMTString().split(' ')[4]}-${new Date(date + (nextDuration * 60000)).toGMTString().split(' ')[4]}`
    },
    setEvent(vander) {
      this.isSubmited = true,
        this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      let startDummyDate = new Date(Date.parse(`Tue Dec 05 1995 ${this.selectzone.split('-')[0]} GMT+0530`))
      let endDummyDate = new Date(Date.parse(`Tue Dec 05 1995 ${this.selectzone.split('-')[1]} GMT+0530`))

      this.selectDate.setHours(startDummyDate.getHours())
      this.selectDate.setMinutes(startDummyDate.getMinutes())
      this.selectDate.setSeconds(startDummyDate.getSeconds())
      this.selectDate = new Date(this.selectDate)

      let startTime = this.selectDate.toISOString()

      this.selectDate.setHours(endDummyDate.getHours())
      this.selectDate.setMinutes(endDummyDate.getMinutes())
      this.selectDate.setSeconds(endDummyDate.getSeconds())
      this.selectDate = new Date(this.selectDate)

      let endTime = this.selectDate.toISOString()

      this.eventPayload = {
        vendor_id: this.selectVanderId,
        order_id: 23,
        title: this.eventName,
        start: startTime,
        end: endTime,
      }

      this.setServiceUser(vander)
      this.isShowSelectModal = false


      this.selectDate = null
      this.eventName = null
      this.selectSlot = null
      this.isSubmited = false
    },
    // stayEventZone() {
    //   if (this.filterEventPerdate.length > 0) {

    //     return this.filterEventPerdate.map(
    //       (curr) => {
    //         let Startime = new Date(Date.parse(curr.start))
    //         let Endtime = new Date(Date.parse(curr.end))
    //         return `${String(Startime.getHours()).padStart(2, '0')}:${String(Startime.getMinutes()).padStart(2, '0')}:${String(Startime.getSeconds()).padStart(2, '0')}-${String(Endtime.getHours()).padStart(2, '0')}:${String(Endtime.getMinutes()).padStart(2, '0')}:${String(Endtime.getSeconds()).padStart(2, '0')}`
    //       }
    //     )
    //   }
    // }
  },
};
</script>

<template>
  <Layout>
    <div class="row" data-v-4b4a7f79="">
      <div class="col-12">
        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 class="mb-sm-0">{{ titles }}</h4>
          <button class="align-items-center btn btn-primary d-flex" v-if="step > 1"
            @click=" isShowModal ? isShowModal = false : step = step - 1">Go
            Back</button>
        </div>
      </div>
    </div>

    <div class="row" v-if="step == 1">
      <div class="col-xxl-3 col-md-4 col-sm-6 col-xs-12 mb-3" v-for="(sCategory, index) in getScategory" :key="index">
        <div class="card h-100"
          :class="serviceOrder.service_category_id == sCategory.service_categories_id ? 'active-border' : ''">
          <img class="service-image" v-if="sCategory.image" :src="getImage(sCategory.image)" alt="Card image cap">
          <div class="avatar-title rounded bg-soft-primary text-primary custom-title" v-else>
            {{ nameInitials(sCategory.service_categories_name) }}</div>
          <div class="card-body">
            <h4 class="card-title mb-2">{{ sCategory.service_categories_name }}</h4>
            <p class="card-text" v-html="sCategory.service_categories_description"></p>
          </div>
          <div class="card-footer text-center d-flex justify-content-center">
            <button class="align-items-center btn btn-primary btn-rounded d-flex"
              @click="setCategory(sCategory.service_categories_id)">View Services</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="step == 2">
      <div class="col-xxl-3 col-md-4 col-sm-6 col-xs-12 mb-3" v-for="(service, index) in services" :key="index">
        <div class="card h-100" :class="serviceOrder.service_id == service.id ? 'active-border' : ''">
          <img class="service-image" v-if="service.service_image" :src="getImage(service.service_image)"
            alt="Card image cap">
          <div class="avatar-title rounded bg-soft-primary text-primary custom-title" v-else>{{
            nameInitials(service.title) }}</div>
          <div class="card-body">
            <h4 class="card-title mb-2">{{ service.title }}</h4>
            <p class="card-text" v-html="service.description"></p>
          </div>
          <div class="card-footer text-center d-flex justify-content-center">
            <button class="align-items-center btn btn-primary btn-rounded d-flex" @click="setService(service)">View
              Details</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="step == 3 && selectedService && !isShowModal">
      <div class="col-xxl-4 col-md-4 col-sm-6 col-xs-12 mb-3" v-for="(u, index) in users" :key="index">
        <div class="card h-100" :class="serviceOrder.user_id == u.user_id ? 'active-border' : ''">
          <!-- <img class="service-image" v-if="selectedService.service_image" :src="getImage(selectedService.service_image)"
            alt="Card image cap">
          <div class="avatar-title rounded bg-soft-primary text-primary" v-else>{{ nameInitials(selectedService.title) }}
          </div> -->
          <div class="card-body mt-1">
            <div class="d-flex mb-2">
              <div class="flex-shrink-0 me-3">
                <div class="avatar-lg">
                  <img v-if="u.user && u.user.user_details && u.user.user_details.passport_photo"
                    :src="`${baseUrl}/${u.user.user_details.passport_photo}`" :alt="img"
                    class="user-image p-1 img-thumbnail rounded-circle" style="aspect-ratio: 1/1;">
                  <span v-else class=" avatar-title rounded bg-soft-primary text-primary fs-1">{{
                    nameInitials(u.user.name) }} </span>
                </div>
              </div>
              <div class="flex-grow-1">
                <h4 class="mb-1">{{ selectedService.title }}</h4>
                <h4 class="mb-1"> <a target="_blank" :href="`/profile/${u.user.name.replace(/\s+/g, '-')}~${u.user.id}`">
                    {{ u.user &&
                      u.user.name ? u.user.name : '' }} </a> </h4>
                <h5 class="mb-1">{{ u.user && u.user.user_businesses && u.user.user_businesses.state ?
                  u.user.user_businesses.state : null }}</h5>
                <star-rating v-model:rating="rating" :read-only="true" :show-rating="false" :star-size="30"></star-rating>
              </div>
              <div>
                <button class="align-items-center btn btn-light btn-rounded d-flex fs-5"
                  v-if="u && u.user && u.user.user_details.tier">&#8377; {{
                    getTierPrice(u.user.user_details.tier) }}</button>
              </div>
            </div>

            <p class="mt-5 mb-0">{{ u.user.user_details.bio }}</p>

            <div class="mt-3" v-if="u && u.user && u.user.role_id == 4">
              <h6>Consultancy Charges</h6>

              <div class="d-flex align-items-center justify-content-between mb-3 pt-1">
                <p class="bg-light py-2 px-3 rounded" type="number"
                  v-if="u && u.user && u.user.user_details && u.user.user_details.consultancy_charge_fifthteen">₹{{
                    u.user.user_details.consultancy_charge_fifthteen }}/ 15 Minutes </p>
                <p class="bg-light py-2 px-3 rounded" type="number"
                  v-if="u && u.user && u.user.user_details && u.user.user_details.consultancy_charge_thirty">₹{{
                    u.user.user_details.consultancy_charge_thirty }}/ 30 Minutes </p>
                <p class="bg-light py-2 px-3 rounded" type="number"
                  v-if="u && u.user && u.user.user_details && u.user.user_details.consultancy_charge_forty">₹{{
                    u.user.user_details.consultancy_charge_forty }}/ 45 Minutes </p>
              </div>

              <button class="btn btn-light" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"
                @click="getAvailability(u.user.user_details.user_id, u.user), u.user.role_id == 4 ? isShowModal = true : setServiceUser(u.user)">Select
                Slot</button>
            </div>
          </div>
          <div class="card-footer text-center d-flex justify-content-center"
            v-if="u.user && u.user.user_details && u.user.user_details.tier">
            <button class="align-items-center btn btn-light btn-rounded d-flex" @click="setServiceUser(u.user)">Select
              Vender</button>
          </div>
        </div>
      </div>
    </div>

    <div class="p-3 rounded mb-3 bg-white" v-if="step == 3 && selectedService && isShowModal">
      <FullCalendar :options="calendarOptions" />
    </div>

    <!-- custom table -->
    <div class="modal fade" :class="isShowSelectModal ? 'show' : ''"
      style="display: block; background: rgba(0, 0, 0, 0.7);" v-if="isShowSelectModal" id="exampleModal" tabindex="-1"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Availability</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              @click="isShowSelectModal = false"></button>
          </div>
          <div class="modal-body">
            <div v-if="!availableTextShow(availabilityFilter(showAvailiblityIndex))">
              <h1 class="text-center m-0">Not Available</h1>
            </div>
            <div v-else>
              <form @submit.prevent>

                <div class="mb-3">
                  <label for="name">Name</label>
                  <input type="text" class="form-control" v-model="eventName" placeholder="Enter Event Name" :class="{
                    'is-invalid': isSubmited && v$.eventName.$error,
                  }">
                  <div v-for="(item, index) in v$.eventName.$errors" :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>

                <div>
                  <label for="slot">Select Slot : </label>
                  <select id="slot" class="form-select" v-model="selectSlot" :class="{
                    'is-invalid': isSubmited && v$.selectSlot.$error,
                  }">
                    <option
                      :value="`${availabilityFilter(showAvailiblityIndex).slot1_start_time}-${availabilityFilter(showAvailiblityIndex).slot1_end_time}`"
                      v-if="availabilityFilter(showAvailiblityIndex).slot1_start_time != availabilityFilter(showAvailiblityIndex).slot1_end_time">
                      {{ availabilityFilter(showAvailiblityIndex).slot1_start_time }} - {{
                        availabilityFilter(showAvailiblityIndex).slot1_end_time }}
                    </option>
                    <option
                      :value="`${availabilityFilter(showAvailiblityIndex).slot2_start_time}-${availabilityFilter(showAvailiblityIndex).slot2_end_time}`"
                      v-if="availabilityFilter(showAvailiblityIndex).slot2_start_time != availabilityFilter(showAvailiblityIndex).slot2_end_time">
                      {{ availabilityFilter(showAvailiblityIndex).slot2_start_time }} - {{
                        availabilityFilter(showAvailiblityIndex).slot2_end_time }}
                    </option>
                    <option
                      :value="`${availabilityFilter(showAvailiblityIndex).slot3_start_time}-${availabilityFilter(showAvailiblityIndex).slot3_end_time}`"
                      v-if="availabilityFilter(showAvailiblityIndex).slot3_start_time != availabilityFilter(showAvailiblityIndex).slot3_end_time">
                      {{ availabilityFilter(showAvailiblityIndex).slot3_start_time }} - {{
                        availabilityFilter(showAvailiblityIndex).slot3_end_time }}
                    </option>
                  </select>
                  <div v-for="(item, index) in v$.selectSlot.$errors" :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>

                <div class="my-3">
                  <label for="duration">Select Duration : </label>
                  <select id="duration" class="form-select" v-model="selectDuarton" :class="{
                    'is-invalid': isSubmited && v$.selectDuarton.$error,
                  }">
                    <option value="15">15 Minute</option>
                    <option value="30">30 Minute</option>
                    <option value="45">45 Minute</option>
                  </select>
                  <div v-for="(item, index) in v$.selectDuarton.$errors" :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>

                <div>
                  <label for="zone">Select Timezone : </label>
                  <select id="zone" class="form-select" v-model="selectzone" :disabled="!selectSlot" :class="{
                    'is-invalid': isSubmited && v$.selectzone.$error,
                  }">
                    <option
                      :value="getTimeZone(selectDuarton * index, selectDuarton * (index + 1), selectSlot.split('-')[0])"
                      :disabled="stayEventZone.includes(getTimeZone(selectDuarton * index, selectDuarton * (index + 1), selectSlot.split('-')[0]))"
                      v-for="(slot, index) in Math.floor(getTimeDiffrent(selectSlot) / selectDuarton)" :key="slot">
                      {{ getTimeZone(selectDuarton * index, selectDuarton * (index + 1), selectSlot.split('-')[0]) }}
                    </option>
                  </select>
                  <div v-for="(item, index) in v$.selectzone.$errors" :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>

                <div class="d-flex justify-content-center mt-4">
                  <button type="submit" class="btn btn-primary" @click="setEvent(selectVanderUser)">Save</button>
                </div>
              </form>

            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row bg-white py-5" v-if="step == 4 && selectedService">
      <div class="col-md-6 px-5">
        <h3 class="mb-4">{{ $t('order.order-detail') }}</h3>
        <div class="row mb-4">
          <div class="col-lg-3">
            <label for="title" class="form-label">{{ $t('order.user-name') }}</label>
          </div>
          <div class="col-lg-9">
            <h6>{{ userData.name }}</h6>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-lg-3">
            <label for="title" class="form-label">Service Name</label>
          </div>
          <div class="col-lg-9">
            <h6>{{ selectedService.title }}</h6>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-lg-3">
            <label for="title" class="form-label">Vendor Name</label>
          </div>
          <div class="col-lg-9">
            <h6>{{ selectedUser.name }}</h6>
          </div>
        </div>

        <div class="row mb-4" v-if="selectedUser.role_id != 4">
          <div class="col-lg-3">
            <label for="phone" class="form-label">{{ $t('order.periodicity') }}</label>
          </div>
          <div class="col-lg-9">
            <div class="mt-4 mt-lg-0">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="onetime" value="onetime" v-model="orderPeriodicity" />
                <label class="form-check-label" for="onetime">{{ $t('order.one-time') }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="monthly" value="monthly" v-model="orderPeriodicity" />
                <label class="form-check-label" for="monthly">{{ $t('order.monthly') }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="quaterly" value="quaterly" v-model="orderPeriodicity" />
                <label class="form-check-label" for="quaterly">Quarterly</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="yearly" value="yearly" v-model="orderPeriodicity" />
                <label class="form-check-label" for="yearly">{{ $t('order.yearly') }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-lg-3">
            <label for="final_amount" class="form-label">{{ $t('order.final-amount') }}</label>
          </div>
          <div class="col-lg-9">
            <input type="number" class="form-control" id="final_amount" :placeholder="$t('order.enter-final-amount')"
              :value="orderAmount" disabled />
          </div>
        </div>

        <div class="mt-3">
          <button @click="placeOrder" class="align-items-center btn btn-primary d-flex">
            {{ $t('order.place-order') }}
          </button>
        </div>

      </div>
    </div>
  </Layout>
</template>

<style scoped>
.service-image {
  width: 100%;
  height: 100%;
  max-height: 200px;
  object-fit: contain;
  border-radius: 7px;
  margin: 12px 0;
}

.custom-title {
  font-size: 60px;
  height: 200px;
}

.active-border {
  border: 2px solid #0D4369;
}
</style>

<style>
.fc-day-past {
  cursor: not-allowed !important;
  background-color: #fafafa;
}

.fc-day {
  cursor: pointer;
}

.user-image {
  width: 100%;
  aspect-ratio: 1;
  height: 100%;
}
</style>
